import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileUpload, GetDelFile } from '../models/common';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { Environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private baseUrl = Environment.apiUrl;
  constructor(private http: HttpClient) { }

   currentToken(): any {
    const token = localStorage.getItem('token');
  return {
    Authorization: `Bearer ${token}`
  };
  }

  addFiles(data: FormData): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/common/addFile`;
    return this.http.post(url, data, { headers }).pipe(
      tap(response => {
        return response
      })
    );
  }

  deleteFile(data: GetDelFile): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/common/deleteS3`;
    const options = {
      headers,
      body: data
    };
    return this.http.delete(url, options).pipe(
      tap(response => {
        return response;
      })
    );
  }

  getFile(data: GetDelFile): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/common/getS3`;
    return this.http.post(url, data, { headers }).pipe(
      tap(response => {
        return response
      }),
      catchError(error => {
        console.error('Error fetching file:', error);
        return throwError('Failed to fetch file');
      })
    );
  }

  getholiday(): Observable<any>{
    const url = `${this.baseUrl}/common/holiday`;
    return this.http.get(url).pipe(
      tap(response => {
        return response
      }),
      catchError(error => {
        console.error('Error fetching Public Holiday list:', error);
        return throwError(error);
      })
    );

  }

}
