<div>

    <table>
        <thead>
          <tr>
            <th><input type="checkbox" class="rowCheckbox"></th>
            <th>No</th>
            <th>Staff ID</th>
            <th>Name</th>
            <th>Onefly ID</th>
            <th>Job Title</th>
            <!-- <th>Date Joined</th> -->
            <th>Status</th>
          </tr>
        </thead>
        <tbody *ngIf="trainerList.length !== 0">
            <ng-container *ngFor="let item of trainerList.slice((currentPage - 1) * pageSize, currentPage * pageSize); let i = index">
              <tr (click)="viewTrainerProfile(item._id)">
                <td><input type="checkbox" class="rowCheckbox"></td>
                <td>{{ (currentPage - 1) * pageSize + i + 1 }}</td>
                <td> {{ item.u_staff_ID ?? 'No staff ID' }}</td>
                <td>{{ item.u_full_name }}</td>
                <td>{{ item.u_one2fly_ID ?? 'No Onefly ID'}}</td>
                <td>{{ item.u_job_title ?? 'No Title' }}</td>
                <!-- <td>
                    <p class="mb-0"> {{ formatDate(item.updatedAt)}}</p>
                    <span>{{ formatDate(item.updatedAt)}}</span>
                </td> -->
                <td>
                  <div [ngClass]="returnStatusBadge(item.u_status)">
                    {{ item.u_status ? 'Active' : 'Suspended'}}
                  </div>
                
                </td>
             
              </tr>
            </ng-container>
        </tbody>
        <tbody *ngIf="trainerList.length === 0">
          <tr>
            <td colspan="9" style="text-align: center; font-style: italic;">No trainer added</td>
          </tr>
        </tbody>
      </table>

    <div class="d-flex justify-content-between footer">
      <div *ngIf="currentPage == totalPages">Showing <span>{{trainerList.length - ((currentPage-1)*pageSize)}}</span> from <span>{{trainerList.length}}</span> results</div>
      <div *ngIf="currentPage != totalPages">Showing <span>5</span> from <span>{{trainerList.length}}</span> results</div>
        <div class="pagination">
          <button (click)="prevPage()" [disabled]="currentPage === 1"><i class="fa-solid fa-chevron-left"></i></button>
          <!-- <span>Page {{ currentPage }} of {{ totalPages }}</span> -->
          <div class="circle-number">
            <span> {{ currentPage }}</span>
          </div>
  
          <button (click)="nextPage()" [disabled]="currentPage === totalPages"><i class="fa-solid fa-chevron-right"></i></button>
        </div>
      </div>
  
</div>