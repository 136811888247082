<div *ngIf="!isLoading" style="padding: 0px 0px 20px 25px; height: calc(100vh - 66px); overflow: auto;">
  <div class="search-container w-100">
    <input type="text" placeholder="Search course here" class="search-input w-100" [(ngModel)]="searchCourseName" (ngModelChange)="searchCourse($event)"/>
</div>
<div class="banner mt-2">
  <ngb-carousel>
    <ng-template ngbSlide *ngFor="let banner of banners; let i = index">
      <div class="carousel-item" [class.active]="true">
        <img [src]="banner.src" class="d-block w-100" [alt]="banner.alt">
      </div>
    </ng-template>
  </ngb-carousel>
</div>
<div class="days" style="justify-content: start; gap: 10px;">
  <div *ngFor="let item of allCategory">
      <div [ngClass]="selectedCat == item.id ? 'container-days-active' : 'container-days'" (click)="selectCategory(item.id)">
          {{ item.title }}
      </div>
  </div>
      
</div>
<div class="container-home mt-3">
    <div class="sort-dropdown" style="width: 180px;">
        <label for="sort">Sort By:</label>
        <select id="sort" [(ngModel)]="filterBy" (ngModelChange)="onFilterChange($event)">
          <option value="">All</option>
          <option value="nameAZ">Ascending</option>
          <option value="nameZA">Descending</option>
          <option value="priceLowHigh">Price</option>
          <option value="favourite">Favourite</option>
        </select>
    </div>
    <div class="view-buttons">
        <button id="list-view" class="list-btn" [ngClass]="{'active': !isGridView}" (click)="isGridView = false;">
          <img [src]="!isGridView ? '../../../assets/icon/list-active.svg' : '../../../assets/icon/list-view.svg'" />
        </button>
        <button id="grid-view" class="grid-btn" [ngClass]="{'active': isGridView}" (click)="isGridView = true;">
          <img [src]="isGridView ? '../../../assets/icon/grid-active.svg' : '../../../assets/icon/grid-view.svg'" />
        </button>
    </div>
</div>
<!-- <button (click)="getUserPermission()">GET USER PERMISSION</button> -->
<div class="row" *ngFor="let item of categoryList" style="overflow: auto;">
    <p>{{ item.cc_display_name }}</p> 
    <div *ngIf="isGridView">
        <app-grid-view [jsonData]="item.courses" (isUpdate)="updateCourse($event)"></app-grid-view>
      </div>
      <div *ngIf="!isGridView">
        <app-list-view [jsonData]="item.courses" (isUpdate)="updateCourse($event)"></app-list-view>
      </div>
    
</div>
</div>

<app-loading-page *ngIf="isLoading"></app-loading-page>

<app-success-modal [isWelcome]="true" [title]="welcomeTitle" [message]="welcomeMessage" *ngIf="!isLoading && !firstPopup" (closeModal)="firstPopup = true;"></app-success-modal>

