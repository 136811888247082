<div class="d-flex" style="gap: 20px;">
  <div class="col-9">
    <div *ngIf="cartList.sessions.length != 0; else isEmpty">
      <div class="cart-list" *ngFor="let session of cartList.sessions; let idx = index;" [ngStyle]="{'margin-top': idx > 0 ? '20px' : '0'}">
        <div class="d-flex flex-row">
            <img class="img" src="../../../assets/img/category_img.png">
            <div>
                <div class="list-title">
                    <span>Session Name: {{ session.s_name}}</span>
                    <span>{{ session.course?.c_course_name }} </span>
                    <span>Category: {{  session.course_category }}</span>
                    <!-- <span>Aerodyne Campus, Cyberjaya, Selangor, Malaysia</span> -->
                    <span>{{  session.venue }}</span>

                </div>
                <div class="d-flex flex-row">
                    <span>{{ DateUtils.formatDate(session.s_start_date)}} - {{ DateUtils.formatDate(session.s_end_date) }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row align-items-center" style="gap: 15px;">
            <span class="list-title">RM {{ session.price }}</span>
        <div class="del-btn" *ngIf="!this.cartList.payment_url" (click)="removeCart(session)">
          <img src="../../../../assets/icon/trash-cart.svg" alt="trash-cart">
        </div>
        </div>

      </div>
    </div>
    <ng-template #isEmpty>
      <span style="font-style: italic;">No Sessions added</span>
    </ng-template>

  </div>
  <!-- <div> -->
    <app-price-summary class="col-3" [totalPrices]="totalPrices" [paymentId]="cartList._id" [paymentUrl]="this.cartList.payment_url ? this.cartList.payment_url : null" *ngIf="cartList.sessions.length != 0;"></app-price-summary>
  <!-- </div> -->
</div>

