<div class="mini-calendar">
    <div class="calendar-header">
      <img alt="arrow-left" src="../../../../assets/icon/arrow-left.svg" (click)="prevMonth()" style="cursor: pointer;  width: 16px; height: 16px;">
      <span style="font-size: 16px;">{{ monthNames[currentDate.getMonth()] }} {{ currentDate.getFullYear() }}</span>
      <img alt="arrow-right" src="../../../../assets/icon/arrow-right.svg" (click)="nextMonth()" style="cursor: pointer; width: 16px; height: 16px;">
    </div>

    <table style="border-style : hidden!important;" cellspacing="0" cellpadding="0">
      <thead class="mini-header">
        <tr style="border: none;">
          <th *ngFor="let day of weekDays" style="border: none;">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let week of calendar; let i = index" style="border: none;">
          <td *ngFor="let day of week" [ngClass]="{'current-day': isSelected(day) }" style="border: none; cursor: pointer;" (click)="selectDay(monthNames[currentDate.getMonth()] + ' ' + currentDate.getFullYear(), day)">
            {{ day }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
