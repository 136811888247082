import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SharedService } from './shared.service';
import { PermissionService } from './permission.service';
import { AuthUpdate, Role, UploadAttachment, User } from '../models/user';
import { TrainerPost } from '../models/trainers';
import { Environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = Environment.apiUrl;

  private checkoutBase = Environment.checkoutBase;
  private paymentUrl = Environment.paymentUrl;
  private paymentUrlCheck = Environment.paymentUrlCheck;
  private readonly USER_KEY = 'currentUser';

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private permissionService: PermissionService
  ) {}

  currentToken(): any {
    const token = localStorage.getItem('token');
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  registerUser(data: any): Observable<any> {
    const url = `${this.baseUrl}/auth/register`;
    return this.http.post(url, data).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  loginUser(data: { email: string; password: string }): Observable<any> {
    const url = `${this.baseUrl}/auth/login`;
    return this.http.post(url, data).pipe(
      tap((response) => {
        this.handleAuthentication(response);
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  logoutUser(): Observable<any> {
    const url = `${this.baseUrl}/auth/logout`;

    const headers = this.currentToken()!;
    return this.http.post(url, {}, { headers }).pipe(
      tap(() => {
        localStorage.clear();
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  private handleAuthentication(response: any): void {
    const { user, token, refreshToken, tokenExpires } = response;

    // Store user data and tokens in localStorage
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('tokenExpires', tokenExpires.toString());

    this.permissionService.setUserPermissions(response.user.u_role_id.r_access);

    // Set user data in SharedService
    this.sharedService.setUserData(user);
  }

  isAuthenticated(): boolean {
    // Check if the token is still valid based on expiration timestamp
    const tokenExpires = localStorage.getItem('tokenExpires');
    return tokenExpires ? Date.now() < +tokenExpires : false;
  }

  getPdfFile(item: string): Observable<any> {
    const url = `${this.baseUrl}/course-module/getS3`;

    const headers = this.currentToken()!;

    return this.http.post(url, { url: item }, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  // TRAINER API
  getTrainer(id: string): Observable<any> {
    const url = `${this.baseUrl}/user/trainer`;
    const params = new HttpParams()
      .set('id', id)
      .set('skip', '0')
      .set('limit', '100');

    const headers = this.currentToken()!;
    return this.http.get(url, { headers, params }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getTrainerWithoutId(): Observable<any> {
    const url = `${this.baseUrl}/user/trainer`;
    const params = new HttpParams()
      .set('id', '')
      .set('skip', '0')
      .set('limit', '100');

    const headers = this.currentToken()!;
    return this.http.get(url, { headers, params }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }


  getUserDetails(id: string): Observable<any> {
    const url = `${this.baseUrl}/user/get/${id}`;
    const headers = this.currentToken()!;
    return this.http.get(url, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  deleteUser(id: string): Observable<any> {
    const url = `${this.baseUrl}/user/${id}`;
    const headers = this.currentToken()!;
    return this.http.delete(url, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  disableUser(id: string, data: any): Observable<any> {
    const url = `${this.baseUrl}/user/${id}`;
    const headers = this.currentToken()!;
    return this.http.patch(url, data, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getTrainerStatistics(id: string): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/user/trainer_statistics/${id}`;
    return this.http.get(url, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getTrainerHistory(id: string): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/session/by-trainer/${id}`;
    return this.http.get(url, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  updateUser(data: User): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/user/${data._id}`;
    return this.http.put(url, data, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  uploadCertificates(data: FormData, id: string): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/user/create/certificate/${id}`;
    return this.http.post(url, data, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getStudents(sessionId: string): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/session/trainees/${sessionId}`;
    return this.http.get(url, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getRole(id: string | Role): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/auth/role/${id}`;
    return this.http.get(url, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getAllRoles(): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/auth/role`;
    return this.http.get(url, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  addNewTrainer(data: TrainerPost): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/user/create/trainer`;
    return this.http.post(url, data, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  updateTrainerProfile(data: any, id: string): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/user/trainer/${id}`;
    return this.http.put(url, data, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  changePassword(data: AuthUpdate): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/auth/update`;
    return this.http.patch(url, data, { headers }).pipe(
      map((response) => {
        // Assuming your response structure has a property like 'success' indicating success or failure
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  // checkout global session
  getSessionData(endpoint: string): Observable<any> {
    return this.http.get(`${this.checkoutBase}${endpoint}`);
  }

  processPayment(data: any): Observable<any> {
    return this.http.post(this.paymentUrl, data);
  }
  getPaymentDetails(stripeId: string): Observable<any> {
    const headers = this.currentToken()!;
    const url = this.paymentUrlCheck;
    const body = { stripeId: stripeId };
    return this.http.post(url, body, { headers }).pipe(
      tap((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }
}
