<div class="general">
  <app-header></app-header>
  <div class="header-booking">
    Review Order
  </div>
  <div class="content-layout" *ngIf="courseDetails.cart">
    <div class="header-text">
      <p class="details" style="color: #0E6027;">Confirm</p>
      <!-- {{sessionDate}} -->
      <p class="txt">Your session on <span class="bold"> {{ DateUtils.formatDate(courseDetails.cart.s_start_date)}} - {{ DateUtils.formatDate(courseDetails.cart.s_end_date)}}</span> is confirmed</p>
      <p><img src="../../../assets/icon/tick-circle.png" alt="learn-more" class="icon">Booking confirmation has send to <a href="{{userData.u_email}}">{{userData.u_email}}</a></p>
      <p><img src="../../../assets/icon/tick-circle.png" alt="learn-more" class="icon">Save your course details by clicking on these two buttons.</p>
    </div>

    <button type="button" class="btn button" (click)="printCourseDetails()" style="margin-right: 12px;"><img src="../../../assets/icon/printer.svg" alt="learn-more" class="btn-icon">Print Course Details</button>
    <button type="button" class="btn button" (click)="saveDetails()"><img src="../../../assets/icon/download.svg" alt="learn-more" class="btn-icon">Save Details</button>
    <div class="alert">
      <img src="../../../assets/icon/info-circle.png" alt="learn-more" class="icon">Protect your security by never sharing your personal or credit card information over the phone, by email or chat.
    </div>

    <div class="header-text">
      <p class="title">Your booking summary:</p>

      <p class="details">{{courseDetails.cart.s_course_id.c_course_id}}</p>
      <p class="details">{{courseDetails.cart.s_course_id.c_course_name}}</p>
      <!-- <p class="details">{{courseDetails.courseModules.length}} Modules, 1 Theory Exam, 1 Flying Practical</p> -->
      <!-- <p class="details">3 Modules, 1 Theory Exam, 1 Flying Practical</p> -->

      <!-- <div *ngFor="let session of courseDetails.sessions; let i = index" class="session"> -->
      <!-- <b>Session {{i+1}}</b> -->
      <b> {{ courseDetails.cart.s_name }}</b>
      <div class="d-flex align-items-center" style="gap: 5px;">
        <span>Date: </span>
        {{ DateUtils.formatDate(courseDetails.cart.s_start_date)}} - {{ DateUtils.formatDate(courseDetails.cart.s_end_date)}}
      </div>
      <div>
        <span>Venue: </span>
        <span>{{ courseDetails.cart.venue}}</span>
      </div>

      <!-- </div> -->
      <div class="mt-2">
        <!-- <p class="details">RM {{courseDetails.c_price_per_pax}}</p> -->
        <p class="details">RM {{ courseDetails.cart.s_course_id.c_price_per_pax }} </p>
      </div>
    </div>

    <div>
      <button type="button" class="btn button" style="width: 1120px;" (click)="goCoursePage()">Go to My Course page</button>
    </div>

  </div>
</div>
