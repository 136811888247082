import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, of, tap } from 'rxjs';
import { CourseGeneralPost, CourseList } from 'src/app/models/course-interface';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {
  imageUrls: { [id: string]: string } = {};
  constructor(private router: Router, private commonService: CommonService) {}
  @Input() jsonData: CourseList[] = [];

  @Output() isUpdate: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit() {
  }

  onCardClick(id: string) {
    // Navigate to the DetailComponent with the id parameter
    this.router.navigate(['/pages/home', id]);
  }

  viewDetails(id: string) {
    this.router.navigate(['/pages/details', id]);
  }

  updateCourse(type: boolean | undefined, id: string) {
    type = !type
    const all = {
      type,
      id
    }
    this.isUpdate.emit(all)
  }

  getImg(id: string): Observable<string> {
    if (this.imageUrls[id]) {
      return of(this.imageUrls[id]);
    }
    return this.commonService.getFile({ id: id }).pipe(
      tap((res) => {
        this.imageUrls[id] = res.url;
      }),
      map((res) => res.url)
    );
  }
}
