<div class="" style="margin-left: 0px; margin-right: 0px; padding-left: 0px;">
    <div class="d-flex flex-column" style="gap: 15px;" *ngIf="jsonData.length != 0">
        <div class="" *ngFor="let module of jsonData" (click)="onCardClick(module._id)">
            <div class="">
                <div class="list-card">
                    <div class="d-flex col-10">
                  
                    <div style="position: relative;">
                        <div class="HVnXkD" *ngIf="module.c_hrdc_claimable">
                            <div class="_96kCjC acSk8Y"
                                style="color: var(--text-text-on-color, var(--Primary, #FFF));font-size: 12px;">
                                <div class="H7ah+G">HRDC</div>
                            </div>
                        </div>
                        <div (click)="updateCourse(module.is_fav, module._id)" class="fav-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
                                <path d="M11.9878 3.593C12.6628 2.43403 14.3372 2.43403 15.0122 3.593L17.2047 7.3573C17.5226 7.90316 18.0554 8.29022 18.6728 8.42391L22.9304 9.34588C24.2412 9.62973 24.7586 11.2221 23.8649 12.2222L20.9624 15.4707C20.5415 15.9417 20.338 16.568 20.4016 17.1965L20.8405 21.5306C20.9756 22.865 19.621 23.8491 18.3937 23.3083L14.4073 21.5516C13.8293 21.2969 13.1707 21.2969 12.5927 21.5516L8.60631 23.3083C7.37897 23.8491 6.02442 22.865 6.15953 21.5306L6.59835 17.1965C6.66199 16.568 6.4585 15.9417 6.0376 15.4707L3.13506 12.2222C2.24142 11.2221 2.75881 9.62973 4.06965 9.34588L8.32724 8.42391C8.94462 8.29022 9.47737 7.90316 9.7953 7.3573L11.9878 3.593Z" 
                                [attr.fill]="module.is_fav ? '#DDFF55' : 'white'" [attr.stroke]="module.is_fav ? 'grey' : '#161616' " stroke-width="0.5"/>
                              </svg>
                        </div>
                        <img *ngIf="module.c_file == null" src="../../../assets/img/category_img.png"  class="card-img-top" alt="Card Image">
                        <img *ngIf="module.c_file != null" [src]="getImg(module.c_file._id) | async"  class="card-img-top" alt="Card Image">
                    </div>
                   
                    <div class="details">
                        <!-- <p class="module-id">{{ module.c_course_id}}</p> -->
                        <p class="module-name">{{ module.c_course_name}}</p>
                        <p class="module-id">{{module.c_course_description}}</p>
                        <p class="module-name">RM {{module.c_price_per_pax}} / pax</p>
                        <span>{{module.c_course_duration}} Days</span>
                    </div>
                </div>
                <div class="learn-more col-2" (click)="viewDetails(module._id)">
                    Learn More &nbsp;<img src="../../../assets/icon/learn-more-black.svg" alt="learn-more" style="width: 20px; height: 20px;">
                </div>

                </div>
            </div>
            <!-- <hr /> -->
        </div>
    </div>
    <div *ngIf="jsonData.length == 0">
        <span style="text-align: center; font-style: italic;"> No courses registered yet.</span>
    </div>
</div>

