import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BookingCartList } from 'src/app/models/booking';
import { SessionList, VenueList } from 'src/app/models/session';
import { BookingService } from 'src/app/services/booking.service';
import { CourseService } from 'src/app/services/course.service';
import DateUtils from "../../../utils/date";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  constructor(private courseService: CourseService, private bookingService: BookingService, private toastr: ToastrService) { }
  @Output() refreshCart: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() cartList: BookingCartList = {
    createdAt: '',
    sessions: [],
    updatedAt: '',
    user: '',
    _id: ''
  }

  totalPrices: number = 0;
  allVenue: VenueList[] = [];

  checkedItems: string[] = []

  ngOnChanges() {
    if (this.cartList) {
      if (this.cartList.sessions.length != 0) {

          this.cartList.sessions = this.cartList.sessions.map(item => {
            const venue = this.getVenue(item.s_venue)
            return {
              ...item,
              course_category: typeof item.course?.c_course_category == 'string' ? '--' : item.course?.c_course_category?.cc_display_name,
              price: item.course?.c_price_per_pax,
              venue,
            }

          })

          this.totalPrices = this.cartList.sessions
          .map(item => item.course!.c_price_per_pax)
          .reduce((total, price) => total + price, 0);
      }
    }
  }

  removeCart(session: SessionList) {
    this.bookingService.removeSession(session._id).subscribe(res => {
      this.toastr.success('Session is successfully removed!', 'Success', {
        timeOut: 3000,
        progressBar: true,
      });

      this.refreshCart.emit(true);
    })
  }

  getVenue(id: string) {
      return this.allVenue.filter((item: VenueList) => item._id == id)[0].v_display_name
  }

  ngOnInit() {
    this.courseService.getCourseTrainingCategory('venue').subscribe(res => {
      this.allVenue = res
    })
  }

  protected readonly DateUtils = DateUtils;
}
