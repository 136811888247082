import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { CourseGeneralPost } from '../models/course-interface';
import { Environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private http: HttpClient) { }

  private baseUrl = Environment.apiUrl;

  currentToken(): any {
    const token = localStorage.getItem('token');
  return {
    Authorization: `Bearer ${token}`
  };
  }

    updateFav(type: boolean, id: string): Observable<any> {
      const headers = this.currentToken()!
    const url = `${this.baseUrl}/course/favourite/${id}`;
    const postType = type ? this.http.patch(url, {}, { headers }) : this.http.delete(url, { headers })
    return postType.pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  // COURSE API
  getCourses(id: string, categoryId: string, trainingId: string, filterBy: string, search: string, limit: number = 10, page: number = 0, status: boolean | number | null = null, own: string): Observable<any> {
    const url = own != '' && own != 'admin' ? `${this.baseUrl}/course/involved` : `${this.baseUrl}/course/general`;
    // const url = `${this.baseUrl}/course/general`;
    // TODO: Change api for trainee
    let params = new HttpParams()
      // .set('id', id)
      .set('skip', page)
      .set('limit', limit)

      if (own && own.trim() !== '') {
        params = params.set('role', own);
      }

    if (search && search.trim() !== '') {
      params = params.set('search', search);
    }

    if (status != null) {
      params = params.set('status', status);
    }

    if (filterBy && filterBy.trim() !== '') {
      params = params.set('filterby', filterBy);
    }

    if (categoryId && categoryId.trim() !== '') {
      params = params.set('course_category', categoryId);
    }

    // .set('training_method', trainingId)
    const headers = this.currentToken()!
    return this.http.get(url, { headers, params }).pipe(
      tap(response => {
        console.log("COURSE RESPONE", response);
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  updateCourse(id: string, data: CourseGeneralPost | any): Observable<any> {
    const url = `${this.baseUrl}/course/general/${id}`;
    const headers = this.currentToken()!
    return this.http.put(url, data, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );

  }

  deleteCourse(id: string): Observable<any> {
    const url = `${this.baseUrl}/course/general/${id}`;
    const headers = this.currentToken()!
    return this.http.delete(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
    }

  getCourseTrainingCategory(type: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-master/${type}`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  createCourseGeneral(data: CourseGeneralPost): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course/general`;
    return this.http.post(url, data, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  checkCourse(data: object): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course/check/id`;
    return this.http.post(url, data, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getCourseDetails(id: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course/general/${id}`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  getCourseGeneral(status: boolean, course_category: string): Observable<any> {
    const headers = this.currentToken()!;
    const url = `${this.baseUrl}/course/general?status=${status}&course_category=${course_category}`;

    return this.http.get<any>(url, { headers }).pipe (
      tap(response => {
        return response
      }),
      catchError(error => {
        console.error('Error fetching course list:', error);
        return throwError(error);
      })
    )
  }

  getCourseModule(): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-module`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  getCourseModuleById(id:String): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-module/${id}`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  getCourseModuleList(id: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-module/list/${id}`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  getClassType(): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-master/class_type`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }

  getCourseCategory(): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-master/course_category`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    )
  }


  createCourseModule(data: FormData): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-module`;
    return this.http.post(url, data, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  updateCourseModule(data: FormData, id: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-module/${id}`;
    return this.http.put(url, data, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  updateCourseItinerary(data:any): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-module/itinerary`;
    return this.http.post(url, data, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  deleteModule(id: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/course-module/${id}`;
    return this.http.delete(url, { headers }).pipe(
      tap(response => {
        return response
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

    getSessionCourse(id: string): Observable<any> {
      const headers = this.currentToken()!
    const url = `${this.baseUrl}/session/by-course/${id}`;
    return this.http.get(url, { headers }).pipe(
      tap(response => {
        return response;
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getItenary(): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/itenary/`;
    return this.http.get(url, { headers }, ).pipe(
      tap(response => {
        return response;
      }),
       catchError(error => {
        console.error('Error fetching cart list:', error);
        return throwError(error);
      })
    );
  }

  getCoverTemplates(type: string): Observable<any> {
    const headers = this.currentToken()!
    const url = `${this.baseUrl}/common/unsplash/template?${type == 'avail' ? 'query=background abstract&per_page=10&orientation=landscape' : 'query=drone device&per_page=10&orientation=landscape'}`;
    return this.http.get(url, { headers }, ).pipe(
      tap(response => {
        return response;
      })
    );
  }

}
