import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, catchError, debounceTime, filter, of } from 'rxjs';
import { CourseCategory, CourseGeneralPost, CourseList } from 'src/app/models/course-interface';
import { User } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { CourseService } from 'src/app/services/course.service';

interface CategorySelect {
  title: string;
  id: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  firstPopup: boolean = false;

  banners = [
    { src: 'assets/img/banner-1.png', alt: 'Banner 1' },
    { src: 'assets/img/banner-2.jpg', alt: 'Banner 2' },
    { src: 'assets/img/banner-3.jpg', alt: 'Banner 3' },
    { src: 'assets/img/banner-4.jpg', alt: 'Banner 4' },
  ];

  constructor(private apiService: ApiService, private toastr: ToastrService, private courseService: CourseService) { 
  }
  filterBy: string = '';
  isLoading: boolean = false;
  title = 'afi-lms';
  sidebarExpanded = true;
  userId: string = ''

  categoryList: CourseCategory[] = [];
  originalCategories: CourseCategory[] = [];
  selectedCat: string = '';
  allCategory: CategorySelect[] = [];

  isGridView: boolean = true;
  isListView: boolean = false;
  searchCourseName: string = '';
  allUserFavCourse: string[] | undefined = [];
  searchCourseName$ = new Subject<void>();


  welcomeTitle: string = 'Welcome';
  welcomeMessage: string = 'Just getting started? Let’s take a look at some of the great features of Aerodyne Flight Institute.'

  
  ngOnInit() {
    this.isLoading = true;

    const storedUserData = localStorage.getItem('currentUser');
   
    if (storedUserData) {
      
      const isUser = JSON.parse(storedUserData);
      this.userId = isUser._id
      this.welcomeTitle = `Welcome ${isUser.u_full_name}!`
      this.welcomeMessage = isUser.u_NRIC_Passport != null ? "Ready to dive into learning? Let's make it a great one together!!" : this.welcomeMessage
    }

    this.firstPopup = localStorage.getItem('isRouterLink') == 'true'
    this.getCategoryList();
    this.getCourseList();

    this.searchCourseName$
    .pipe(debounceTime(1000))
    .subscribe(() => {
      // Call the search function after debounce time
      this.getCourseList();
    });
    
   
  }

  getUserFav() {
    this.apiService.getUserDetails(this.userId).subscribe((res: User) => {
      
      this.allUserFavCourse = res.u_fav_course;
      this.categoryList = this.categoryList.map(item => ({
        ...item,
        courses: item.courses.length != 0 ? item.courses.map((data: CourseList) => ({ ...data, is_fav: this.allUserFavCourse?.includes(data._id)})) : []
      }))

    })
  }

  searchCourse(courseName: string) {
    this.searchCourseName$.next();
  }


  onFilterChange(value: string) {
    // You can perform any additional actions here
    this.filterBy = value
    this.getCourseList()
  }

  getCategoryList() {
    this.courseService.getCourseTrainingCategory('course_category').subscribe(res => {
      this.categoryList = res;

      this.allCategory = res.map((item: CourseCategory) => ({
        title: item.cc_display_name,
        id: item._id
      }));

      this.allCategory.unshift({
        title: 'All',
        id: '',
      })

      this.originalCategories = this.categoryList
    })
  }

  getCourseList() {
    this.courseService.getCourses('', this.selectedCat, '', this.filterBy, this.searchCourseName.toLowerCase(), 100, 0, true, '').subscribe(res => {
      this.categoryList = this.categoryList.map(item => ({
        ...item,
        courses: res ? res.courses.filter((data: CourseList) => this.checkType(data) == item._id) : []
      }))
      this.getUserFav();


        setTimeout(() => {
          this.isLoading = false;
        }, 1000);

    })
  }

  checkType(val: CourseList) {
    let textLabel: string | undefined = ''
    if (typeof val.c_course_category === 'string') {
      textLabel = val.c_course_category
    } else {
      textLabel = val.c_course_category?._id
    }
  
    return textLabel
  }

  updateCourse(val: { type: boolean; id: string}) {
    // update course here
    this.courseService.updateFav(val.type, val.id).pipe(
      catchError(err => {
        console.log('Error occurred:', err);
        // Handle the error appropriately, e.g., show a toast message
        this.toastr.error('An error occurred while updating the course.', 'Error', {
          timeOut: 3000,
          progressBar: true,
        });

        this.getCourseList();
        // Return an empty observable to gracefully handle the error
        return of(null);
      })
    ).subscribe(res => {
      if (res) {
        this.toastr.success('Course is successfully updated!', 'Success', {
          timeOut: 3000,
          progressBar: true,
        });
       this.getUserFav()
    
        // if (this.selectedCat === '' && !updateData.c_is_favourite) { // if category is all & isFavourite is false
        //   this.getCourseList();
        // }
      }
    });
  }

  selectCategory(id: string) {
    console.log(id, this.originalCategories)
    // this.getCategoryList()
   
    if (id == '') {
      this.selectedCat = ''
      this.getCategoryList()
     
    } else {
      this.selectedCat = id;
      this.categoryList = this.originalCategories.filter(item => item._id == id)
      
    }
    this.getCourseList()
    console.log('>> asal lak', this.categoryList)
  
  }
}
