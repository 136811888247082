<div class="container" style="height: 100%; overflow: auto;">
    <div class="row" style="padding: 20px;">
        <div class="col-2">
            <p>Add Module Details</p>
            <span>Kindly provide the module details or upload any materials needed.</span>
        </div>
        <div class="col-10">
            <div class="d-flex justify-content-end w-100">
                <button class="btn btn-add" (click)="addNewModule()">
                    <i class="fa fa-add"></i>  Add Modules
                  </button>
            </div>
            <div class="mt-3">
                <div class="module-table">
                    <div class="d-flex justify-content-between w-100">
                        <div class="d-flex">
                            <div class="sort-dropdown">
                                <label for="sort">Show:</label>
                                <!-- (ngModelChange)="changePerPage($event)" -->
                                <select id="sort" [(ngModel)]="showBy">
                                  <option [value]="10">10</option>
                                  <option [value]="20">20</option>
                                  <option [value]="30">30</option>
                              </select>
                            </div>
                            <div class="sort-dropdown">
                                <label for="sort">Filter By:</label>
                                <select id="sort">
                                    <option value="name">All</option>
                                    <option value="date">Date</option>
                                    <option value="price">Price</option>
                                </select>
                            </div>
                        </div>
                            <div class="search-container">
                                <input type="text" placeholder="Search..." class="search-input" [(ngModel)]="searchModule" (input)="onSearchInputChange()"/>
                            </div>
                    </div>
                    <div class="d-flex flex-column w-100"  style="height: 40vh; overflow: auto;">
                      <table>
                        <thead style="position: sticky; top: 0; z-index: 10;">
                          <tr>
                            <!-- <th><input type="checkbox" class="rowCheckbox"></th> -->
                            <th>No.</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Class Type</th>
                            <th>Duration</th>
                            <!-- <th>File Name</th> -->
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="listModules.length !== 0">
                          <ng-container *ngFor="let item of listModules.slice((currentPage - 1) * pageSize, currentPage * pageSize); let i = index">
                            <tr>
                              <!-- <td><input type="checkbox" class="rowCheckbox"></td> -->
                              <td>{{ (currentPage - 1) * pageSize + i + 1 }}.</td>
                              <td>{{ item.cm_title }}</td>
                              <td>{{ item.cm_description }}</td>
                              <td>{{ item.class_type }}</td>
                             <td>{{ item.cm_duration }}</td>
                             <!-- <td>  
                                    <div class="module-files" *ngFor="let item of item.cm_files" (click)="viewPdf(item)">
                                        <img src="../../../../assets/icon/pdf.svg" alt="pdf-module">
                                        <span> {{ item.f_path.split('/')[1] }} </span>
                                    </div>
                              
                             </td> -->
                              <td>
                                <div class="d-flex" style="gap: 10px;">
                                    <img src="../../../../assets/icon/edit-module.svg" alt="edit-module" (click)="editModule(item, item._id)" style="width: 20px; height: 20px;">
                                    <img src="../../../../assets/icon/delete-module.svg" alt="delete-module" (click)="isConfirmDelete(item._id)" style="width: 20px; height: 20px;">
                                </div>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                        <tbody *ngIf="listModules.length === 0">
                            <tr style="border-bottom: none;">
                              <td colspan="9">
                                <div class="d-flex justify-content-center align-items-center flex-column p-3">
                                    <img src="../../../../assets/icon/no-modules.svg" style="width: 100px; height: 100px;" alt="no-module">
                                    <span class="desc-no-module">Let's get started by adding modules. Click “New Module”, add your module details, upload materials and save.</span>
                                </div>
                            </td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                    <div class="d-flex justify-content-between footer w-100" *ngIf="listModules.length !== 0" style="margin-bottom: 0px; padding: 0 10px 0 10px;">
                      <div *ngIf="currentPage == totalPages">Showing <span>{{listModules.length - ((currentPage-1)*pageSize)}}</span> from <span>{{listModules.length}}</span> results</div>
                      <div *ngIf="currentPage != totalPages">Showing <span>5</span> from <span>{{listModules.length}}</span> results</div>
                      <div class="pagination">
                        <button (click)="prevPage()" [disabled]="currentPage === 1"><i class="fa-solid fa-chevron-left"></i></button>
                        <!-- <span>Page {{ currentPage }} of {{ totalPages }}</span> -->
                        <div class="circle-number">
                          <span> {{ currentPage }}</span>
                        </div>
                    
                        <button (click)="nextPage()" [disabled]="currentPage === totalPages"><i class="fa-solid fa-chevron-right"></i></button>
                      </div>
                    </div>
                 
                        
                </div>
            </div>
        </div>
    </div>
</div>