export default class DateUtils {
  static formatDate(date: Date | string | number, format: 'yyyy-MM-dd' | 'dd MMM yyyy' = 'dd MMM yyyy'): string {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');

    if (format === 'yyyy-MM-dd') {
      return `${year}-${month}-${day}`;
    } else {
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthIndex = inputDate.getMonth();
      return `${day} ${monthNames[monthIndex]} ${year}`;
    }
  }
}
